import React, { useState } from "react";

import logo from "../assets/logo-white.svg";
import { Link } from "react-router-dom";

export default function Hero({ heroBg, heroText, button }) {
  return (
    <div
      className="hero"
      style={{
        background: `linear-gradient(to bottom, rgba(50, 50, 50, 0.4), rgba(0, 0, 0, 0.5)),center/cover no-repeat url(${heroBg})`,
      }}
    >
      <Link className="logo" to="/">
        <img src={logo} />
      </Link>
      <div className="hero-text">
        <h6>{heroText}</h6>
        {button && (
          <Link to={button.link}>
            <button>{button.text}</button>
          </Link>
        )}
      </div>
    </div>
  );
}
