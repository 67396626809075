import React from "react";

export default function SubQuality({ icon, text }) {
  return (
    <section className="subQ">
      <img src={icon} alt={text + "- icon"} />
      <h4>{text}</h4>
    </section>
  );
}
