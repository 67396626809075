import medicalImage from "./assets/content/medical.png";
import legalImage from "./assets/content/legal.png";
import educationImage from "./assets/content/education.png";
import educationImage2 from "./assets/content/education1.png";
import pharmacyIcon from "./assets/content/pharmacy.png";
import tabletsImage from "./assets/content/tablets.png";

export const industriesData = [
  {
    id: 1,
    term: "Medical",
    image: medicalImage,
    script:
      "Here at Prospecct Communication we make sure to offer the highest standard quality when to comes to medical interpreting. We offer VRI, OPI, consecutive and simultaneous interpreting with availability 24/7.",

    firstStep: [
      {
        id: 1,
        heading: "Certified Professionals: ",
        paragraph:
          "Our interpreters hold certifications from reputable organizations such as the Certification Commission for Healthcare Interpreters (CCHI) or the National Board of Certification for Medical Interpreters (NBCMI). This ensures a high standard of linguistic and medical knowledge.",
      },
      {
        id: 2,
        image: tabletsImage,
        secondStep: [
          {
            id: 1,
            heading: "Advanced Medical Training:",
            paragraph:
              "In addition to language proficiency, our interpreters undergo specialized training in medical terminology, procedures, and protocols to provide accurate and precise interpretations.",
          },
          {
            id: 2,
            heading: "Cultural Competence: ",
            paragraph:
              "Our interpreters are culturally sensitive and understand the importance of respecting and accommodating diverse patient backgrounds and beliefs. Hiring native people in their respective languages on the rare languages  makes cultural competence among our strongest points. ",
          },
          {
            id: 3,
            heading: "Ethical Standards: ",
            paragraph:
              "We uphold strict ethical standards, including confidentiality and impartiality, to protect patient privacy and ensure unbiased communication.",
          },
        ],
      },
      {
        id: 3,
        heading: "Medical Settings We Serve:",
        secondStep: [
          {
            id: 1,
            heading: "Hospitals and Clinics: ",
            paragraph:
              "Our interpreters assist in hospitals and clinics, facilitating clear communication between medical professionals and patients for various services including consultations, diagnoses, and treatment plans.",
          },
          {
            id: 2,
            heading: "Emergency Rooms:",
            paragraph:
              "In emergency situations, our interpreters provide rapid and accurate communication to ensure patients receive timely and appropriate care.",
          },
          {
            id: 3,
            heading: "Surgical Centers:",
            paragraph:
              "Our interpreters play a crucial role in surgical settings, supporting clear communication between patients and surgical teams before, during, and after procedures.",
          },
          ,
          {
            id: 4,
            heading: "Mental Health Facilities:",
            paragraph:
              "Our interpreters are trained to handle sensitive conversations and assessments in mental health care, ensuring patients receive proper support.",
          },
          {
            id: 5,
            heading: "Pharmacies: ",
            paragraph:
              "Our interpreters help patients understand their medications, dosage instructions, and potential side effects to promote safe and effective medication use, interpreting , rendering every thing accurately .",
            image: pharmacyIcon,
          },
        ],
      },
    ],
  },
  {
    id: 2,
    term: "Education",
    image: educationImage,
    script:
      "Our interpreters hold certifications from recognized organizations such as the American Translators Association (ATA), the Registry of Interpreters for the Deaf (RID), and other relevant certifying bodies, demonstrating their proficiency and professional credibility.",
    firstStep: [
      {
        id: 1,
        heading: "Our Interpreters' Qualifications:",
        secondStep: [
          {
            id: 1,
            heading: "Language Proficiency:",
            paragraph:
              "Our interpreters are proficient in multiple languages, having achieved high levels of fluency in both the source and target languages. They can accurately convey complex ideas, cultural nuances, and specialized terms.",
          },
          {
            id: 2,
            heading: "Educational Terminology Expertise:",
            paragraph:
              "They have in-depth knowledge of educational terms, practices, and regulations, ensuring clear and precise communication in various educational contexts.",
          },
          {
            id: 3,
            heading: "Special Education Training:",
            paragraph:
              "Interpreters with special education training are familiar with specific terms and protocols related to individualized education programs (IEPs) and other accommodations.",
          },
        ],
      },
      {
        id: 2,
        image: educationImage2,
        secondStep: [
          {
            id: 4,
            heading: "Cultural Competence: ",
            paragraph:
              "Our interpreters understand and respect the cultural diversity of the educational community, ensuring inclusive communication and supporting a welcoming learning environment.",
          },
          {
            id: 5,
            heading: "Experience with Various Learning Environments: ",
            paragraph:
              "Our interpreters are experienced in supporting communication in K-12 schools, higher education, special education, and adult education settings.",
          },
          {
            id: 6,
            heading: "Technical Proficiency: ",
            paragraph:
              "Our interpreters are skilled in using online conferencing tools, learning management systems, and other educational technology to provide interpretation services remotely and efficiently.",
          },
        ],
      },
      {
        id: 3,
        // image: educationImage2,
        secondStep: [
          {
            id: 7,
            heading: "Active Listening and Note-Taking:",
            paragraph:
              "Our interpreters employ active listening and effective note-taking techniques to ensure accurate interpretation and understanding during discussions.",
          },
          {
            id: 8,
            heading: "Clear Communication:",
            paragraph:
              "Our interpreters prioritize clarity and precision in all communications, bridging language gaps and ensuring all parties fully understand the conversation.",
          },
          {
            id: 9,
            heading: "Knowledge of Educational Policies and Regulations:",
            paragraph:
              " Interpreters are familiar with educational policies and regulations at local, state, and national levels, allowing them to navigate and interpret in compliance with current standards.",
          },
        ],
      },
      {
        id: 4,
        // image: educationImage2,
        secondStep: [
          {
            id: 10,
            heading: "Interpersonal Skills:",
            paragraph:
              "Our interpreters possess excellent interpersonal and communication skills, enabling them to build rapport and foster trust between educators, students, and families.",
          },
          {
            id: 11,
            heading: "Ongoing Training and Development:",
            paragraph:
              "Our interpreters regularly participate in professional development programs and workshops to stay up to date with industry trends and best practices.",
          },
          {
            id: 12,
            heading: "Ethical Standards:",
            paragraph:
              "We adhere to strict ethical standards, including maintaining confidentiality, impartiality, and neutrality in all interactions.",
          },
          {
            id: 13,
            heading: "Accessibility Advocacy: ",
            paragraph:
              "Our interpreters are committed to advocating for language access and equity in education, ensuring all students have the opportunity to succeed.",
          },
        ],
      },
      {
        id: 5,
        heading: "Educational Settings We Serve:",
        secondStep: [
          {
            id: 1,
            heading: "K-12 Schools:",
            paragraph:
              "Parent-teacher conferences, IEP meetings, and school events.",
          },
          {
            id: 2,
            heading: "Higher Education: ",
            paragraph:
              "College and university admissions, academic advising, and student services.",
          },
          {
            id: 3,
            heading: "Special Education: ",
            paragraph: "Individualized learning plans and accommodations.",
          },
          {
            id: 4,
            heading: "School Board Meetings:",
            paragraph:
              "Interpretation for school board discussions and decisions.",
          },
          {
            id: 5,
            heading: "Online Education: ",
            paragraph: "Virtual classrooms and remote learning environments.",
          },
          {
            id: 6,
            heading: "Adult Education: ",
            paragraph:
              "Support for adult education programs and vocational training.",
          },
          {
            id: 7,
            heading: "After-School Programs:",
            paragraph:
              "Bridging communication between after-school program staff and families.",
          },
          {
            id: 8,
            heading: "Career and Technical Education (CTE):",
            paragraph: "Vocational and technical training settings.",
          },
          {
            id: 9,
            heading: "International Student Services: ",
            paragraph:
              "Supporting international students with academic and administrative processes.",
          },
        ],
      },
    ],
  },
  {
    id: 3,
    term: "Legal",
    image: legalImage,
    script:
      "Our interpreters hold certifications from reputable organizations such as the National Association of Judiciary Interpreters and Translators (NAJIT) and other recognized certifying bodies, demonstrating their proficiency and professional credibility.",

    firstStep: [
      {
        id: 1,
        heading: "Our Interpreters' Qualifications:",
        secondStep: [
          {
            id: 1,
            heading: "Legal Terminology Expertise:",
            paragraph:
              "Our interpreters own a deep understanding of legal terminology, concepts, and procedures across different areas of law.",
          },
          {
            id: 2,
            heading: "Courtroom Experience: ",
            paragraph:
              "Our interpreters have extensive experience working in courtrooms, ensuring accurate interpretation for hearings, trials, depositions, and other legal proceedings.",
          },
          {
            id: 3,
            heading: "Knowledge of Legal Processes:",
            paragraph:
              "Our interpreters understand legal processes, including filing documents, legal motions, and courtroom protocols.",
          },
          {
            id: 4,
            heading: "Cultural Competence: ",
            paragraph:
              "Our interpreters are culturally sensitive and understand how to navigate the nuances of different cultures, ensuring respectful and inclusive communication.",
          },
          {
            id: 5,
            heading: "Experience in Various Legal Settings:",
            paragraph:
              "Our interpreters are experienced in working in various legal settings such as courts, law firms, police stations, and correctional facilities.",
          },
          {
            id: 6,
            heading: "Attention to Detail:",
            paragraph:
              "Legal interpretation requires precise attention to detail, and our interpreters excel in maintaining accuracy in conveying legal information.",
          },
          {
            id: 7,
            heading: "Discretion and Confidentiality:",
            paragraph:
              "We adhere to strict standards of confidentiality and privacy, safeguarding sensitive legal information.",
          },
          {
            id: 8,
            heading: "Ongoing Training and Development: ",
            paragraph:
              "Our interpreters participate in ongoing professional development programs to stay current with legal trends and industry best practices.",
          },
          {
            id: 9,
            heading: "Impartiality and Neutrality:",
            paragraph:
              "Our interpreters maintain impartiality and neutrality, ensuring fair and unbiased interpretation.",
          },
          {
            id: 10,
            heading: "Technical Proficiency: ",
            paragraph:
              "Our interpreters are skilled in using digital tools and platforms to provide remote interpretation services when necessary.",
          },
          {
            id: 11,
            heading: "Professionalism and Ethical Standards:",
            paragraph:
              "Our interpreters adhere to the highest standards of professionalism and ethics in all legal interpretations.",
          },
        ],
      },
      {
        id: 2,
        heading: "Educational Settings We Serve:",
        secondStep: [
          {
            id: 1,
            heading: "Courts: ",
            paragraph:
              "Our interpreters provide services for civil, criminal, and family courts, supporting communication in trials, hearings, and mediations.",
          },
          {
            id: 2,
            heading: "Depositions:",
            paragraph:
              "We offer interpretation for depositions, ensuring clear communication and accurate transcriptions.",
          },
          {
            id: 3,
            heading: "Arbitration and Mediation:",
            paragraph:
              "Our interpreters facilitate communication in alternative dispute resolution settings, promoting fair and effective resolution processes.",
          },
          {
            id: 4,
            heading: "Law Firms: ",
            paragraph:
              "We assist in client meetings, contract reviews, and other legal consultations, enabling attorneys and clients to communicate effectively.",
          },
          {
            id: 5,
            heading: "Police Stations: ",
            paragraph:
              "Our interpreters support law enforcement by providing services during interrogations, interviews, and other interactions.",
          },
          {
            id: 6,
            heading: "Correctional Facilities: ",
            paragraph:
              "We offer interpretation services for inmates during legal proceedings, assessments, and consultations.",
          },
          {
            id: 7,
            heading: "Immigration Proceedings: ",
            paragraph:
              "Our interpreters assist in immigration courts, supporting individuals and families through the immigration process.",
          },
          {
            id: 8,
            heading: "Probation and Parole Hearings: ",
            paragraph:
              "We provide interpretation for individuals involved in probation or parole hearings.",
          },
          {
            id: 9,
            heading: "Legal Aid Services: ",
            paragraph:
              "Our interpreters support legal aid organizations in providing accessible legal services to underserved communities.",
          },
          {
            id: 10,
            heading: "Remote Legal Services: ",
            paragraph:
              "We offer remote interpretation services to support virtual legal consultations and proceedings.",
          },
          {
            id: 11,
            heading: "Notary Services: ",
            paragraph:
              "Our interpreters provide support for legal notarizations, ensuring clear communication between notaries and clients.",
          },
          {
            id: 12,
            heading: "Patent and Intellectual Property:",
            paragraph:
              "We offer interpretation services for legal consultations and filings related to patents and intellectual property.",
          },
          {
            id: 13,
            heading: "Business and Corporate Law: ",
            paragraph:
              "Our interpreters support business law, including mergers, acquisitions, and corporate contracts.",
          },
          {
            id: 14,
            heading: "Real Estate Transactions: ",
            paragraph:
              "We offer interpretation services for real estate closings, property disputes, and lease agreements.",
          },
          {
            id: 15,
            heading: "Tax Law: ",
            paragraph:
              "Our interpreters provide interpretation for tax law consultations and hearings.",
          },
          {
            id: 16,
            heading: "Employment and Labor Law: ",
            paragraph:
              "We offer interpretation services for employment-related legal matters such as disputes, negotiations, and hearings.",
          },
          {
            id: 17,
            heading: "Bankruptcy Law: ",
            paragraph:
              "Our interpreters assist in bankruptcy courts and legal consultations, supporting communication during complex legal proceedings.",
          },
          {
            id: 18,
            heading: "Juvenile Court: ",
            paragraph:
              "Our interpreters support juvenile court proceedings, including delinquency hearings and custody disputes",
          },
        ],
      },
    ],
  },
];
