import React from "react";
import SubQuality from "./SubQuality";

import suport_icon from "../assets/support.png";
import tech_icon from "../assets/tech.png";
import material_icon from "../assets/material.png";
import pro_icon from "../assets/pro.png";

import onsite_icon from "../assets/onsite.svg";
import Interpretation_icon from "../assets/interpretation.svg";
import Multilingual_icon from "../assets/multi.svg";
import Demand_icon from "../assets/ondemand.svg";
import opi_icon from "../assets/opi.svg";
import video_icon from "../assets/video.svg";
import rsi_icon from "../assets/rsi.svg";
import vri_icon from "../assets/vri.svg";
import Quality from "./Quality";
export default function WhatWeDo({ reference }) {
  const qualities = [
    {
      icon: onsite_icon,
      text: "Onsite Interpreter Scheduling",
    },
    {
      icon: Interpretation_icon,
      text: "Interpretation Management System",
    },
    {
      icon: Multilingual_icon,
      text: "Multilingual Events",
    },
    {
      icon: Demand_icon,
      text: "On-Demand Interpretation",
    },
    {
      icon: opi_icon,
      text: "Over the Phone Interpreting (OPI)",
    },
    {
      icon: video_icon,
      text: "Video Conferencing",
    },
    {
      icon: rsi_icon,
      text: "Remote Simultaneous Interpreting (RSI)",
    },
    {
      icon: vri_icon,
      text: "Video Remote Interpreting (VRI)",
    },
  ];

  return (
    <section className="whatwedo" ref={reference}>
      <h5 className="title">WHAT WE DO</h5>
      <article className="article">
        {/* <h1>Exceptional Quality</h1> */}
        <p>
          We offer a wide range of language services to meet the needs of our
          clients. Our services include consecutive interpretation, simultaneous
          interpretation, and more.
        </p>
      </article>
      <article className="article-2">
        <SubQuality icon={suport_icon} text="24/7 Support" />
        <SubQuality icon={tech_icon} text="Advanced Tech" />
        <SubQuality icon={material_icon} text="First-Rate Materials" />
        <SubQuality icon={pro_icon} text="Licensed Professionals" />
      </article>
      <article className="article-3">
        {qualities.map((d, i) => (
          <Quality key={i} icon={d.icon} text={d.text} />
        ))}
      </article>
    </section>
  );
}
