import React, { useEffect, useLayoutEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
export default function Header({ refss }) {
  const [menu, setMenu] = useState(false);
  const { hash } = useLocation();
  useLayoutEffect(() => {
    if (hash) {
      const id = hash.substring(1);
      setTimeout(() => {
        switch (id) {
          case "whoweare":
            handleLink(refss.section1);
            break;
          case "whatwedo":
            handleLink(refss.section2);
            break;
          case "industries":
            handleLink(refss.section3);
            break;
        }
      }, 0);
    }
  }, [hash]);
  const handleLink = (element) => {
    if (element?.current) {
      element?.current?.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <span
        onClick={() => setMenu(!menu)}
        className={menu ? "menu-icon active" : "menu-icon"}
      >
        <span></span>
      </span>
      <header className={menu && "hero-bar-active"}>
        <nav>
          <ul>
            <li>
              <Link to="/#whoweare" onClick={() => handleLink(refss.section1)}>
                WHO WE ARE
              </Link>
            </li>
            <li>
              <Link to="/#whatwedo" onClick={() => handleLink(refss.section2)}>
                {" "}
                WHAT WE DO
              </Link>
            </li>
            <li>
              <Link
                to="/#industries"
                onClick={() => handleLink(refss.section3)}
              >
                INDUSTRIES
              </Link>
            </li>
            <li>
              <Link to="/opportunity">JOIN OUR TEAM</Link>
            </li>
            <li>
              <Link to="/bookademo">Book a demo</Link>
            </li>
          </ul>
        </nav>
      </header>
    </>
  );
}
