import React from "react";
import MainQualities from "./MainQualities";
import medical from "../assets/content/medical.png";
import { industriesData } from "../data";
export default function Industry({ reference }) {
  return (
    <div className="ind" ref={reference}>
      <h5 className="title">INDUSTRIES</h5>
      <article className="article">
        <h1>Exceptional Quality</h1>
        {/* <p>
          We offer a wide range of language services to meet the needs of our
          clients. Our services include consecutive interpretation, simultaneous
          interpretation, and more.
        </p> */}
      </article>
      <article className="article-2">
        {industriesData.map((d) => (
          <MainQualities key={d.id} image={d.image} heading={d.term} />
        ))}
      </article>
      ;
    </div>
  );
}
