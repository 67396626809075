import React from "react";
import whoweare from "../assets/whoweare.png";
import Statments from "./Statments";
import mission_icon from "../assets/mission.png";
import vision_icon from "../assets/vision.png";
import value_icon from "../assets/values.png";
import Achievement from "./Achievement";
export default function WhoWeAre({ reference }) {
  return (
    <section className="whoweare" ref={reference}>
      <h5 className="title">WHO WE ARE</h5>
      <article className="whoweare-first">
        <aside>
          <h1>Prospecct Communication</h1>
          <p>
            At Prospecct Communication, we offer professional interpretation,
            translation and localization services for up to 100+ languages
            available for 24/7. Our team is made of experienced individuals who
            are passionate about their job are dedicated to serve and are
            experienced in the respective fields. We are dedicated in providing
            accurate and reliable language services to meet all your needs.
          </p>
        </aside>
        <img src={whoweare} />
      </article>
      <article className="statements">
        <Statments
          heading="Our Mission"
          description="To ensure reliability, provide premium excellence, competitive rate while focusing on exceeding our customers' quality expectations."
          image={mission_icon}
          altImage="Prospecct Communication's mission"
        />
        <Statments
          heading="Our Vision"
          description="Our vision is to serve and create a world where communication is seamless and clear, envisioning ourselves as the preferred language solution partner for companies and institutions worldwide, thus enabling our customers' success in the global market."
          image={vision_icon}
          altImage="Prospecct Communication's vision"
        />
        <Statments
          heading="Value Statement"
          description="Our core values are what guide us in every decision, project or partnership that we undertake. Everyone at Prospecct understands and works by these values. This is what will help our costumers succeed."
          image={value_icon}
          altImage="Prospecct Communication's values"
        />
      </article>
      <article className="count">
        <Achievement number="700000+" text="Minutes Per Year" />
        <Achievement number="2M+" text="Words Translated" />
        <Achievement number="99+" text="Languages" />
        <Achievement number="99+" text="Certified Interpreters" />
      </article>
    </section>
  );
}
