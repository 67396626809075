import React from "react";
import { useParams } from "react-router-dom";
import { industriesData } from "../data";
import BookMeet from "../components/BookMeet";

export default function Industries() {
  const { industries } = useParams();
  const [data] = industriesData.filter((d) => d.term === industries);
  console.log(data);
  return (
    <>
      <div className="banner" st>
        <h6>{industries}</h6>
      </div>
      <section className="industries">
        <h2>{data.script}</h2>
        {data?.firstStep?.map((first) => {
          return (
            <article key={first?.id} className={first?.image && "flex-center"}>
              <div>
                {first?.heading && <h3>{first?.heading}</h3>}
                {first?.paragraph && <p>{first?.paragraph}</p>}
              </div>
              <article>
                {first?.secondStep?.map((second) => {
                  return (
                    <div
                      key={second?.id}
                      className={second?.image && "flex-center"}
                    >
                      <div>
                        {second?.heading && <h4>{second?.heading}</h4>}
                        {second?.paragraph && <p>{second?.paragraph}</p>}{" "}
                      </div>
                      {second?.image && (
                        <img className="small-image" src={second?.image} />
                      )}
                    </div>
                  );
                })}
              </article>
              {first?.image && <img src={first?.image} />}
            </article>
          );
        })}
      </section>
      <BookMeet />
    </>
  );
}
