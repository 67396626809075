import React, { useEffect, useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Submited from "../components/Submited";

export default function Opportunity() {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  const [isSubmited, setIsSubmited] = useState(false);
  const handleSubmit = (e) => {
    setIsSubmited(true);
  };
  useEffect(() => {
    if (isSubmited == true) {
      setTimeout(() => {
        setIsSubmited(false);
        document.getElementById("contactform").reset();
      }, 10000);
    }
  }, [isSubmited]);
  return (
    <>
      <div className="banner">
        <h6>JOIN OUR TEAM</h6>{" "}
      </div>
      <iframe
        style={{ display: "none" }}
        name="hidden_iframe"
        id="hidden_iframe"
      ></iframe>

      <form
        style={{
          opacity: isSubmited && 0,
          width: isSubmited && 0,
          height: isSubmited && 0,
        }}
        id="contactform"
        method="post"
        target="hidden_iframe"
        action="https://docs.google.com/forms/u/0/d/e/1FAIpQLSf_uFL3_drpVXnedz0VjsKgEcXBkAqiV_SU4zdEQ4-o2rG0pA/formResponse"
        onSubmit={handleSubmit}
      >
        <div className="form-container">
          <input
            type="text"
            required
            name="entry.1781500597"
            placeholder="First Name"
          />
          <input
            type="text"
            required
            name="entry.230358434"
            placeholder="Last Name"
          />
          <input
            type="text"
            required
            name="entry.1640447617"
            placeholder="Email"
          />
          <input
            type="text"
            required
            name="entry.297979220"
            placeholder="Telephone"
          />
          <input
            type="text"
            required
            name="entry.834516761"
            placeholder="City"
          />
          <input
            type="text"
            required
            name="entry.1430504598"
            placeholder="Country"
          />
          <div className="fullwidth">
            <label htmlFor="message">Attach Your Resume</label>
            <input
              required
              type="text"
              name="entry.562023960"
              placeholder="Add the Link here"
            />
            <span className="mono-text">
              add the resume to your google drive and copy, past the link here.
            </span>
          </div>
          <button className="fullwidth width-16" type="submit">
            Submit
          </button>
        </div>
      </form>
      {isSubmited && <Submited />}
    </>
  );
}
