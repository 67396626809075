import React from "react";

export default function Statments({ heading, description, image, altImage }) {
  return (
    <section className="statements-state">
      <section className="statements-state-text">
        <h2>{heading}</h2>
        <p>{description}</p>
      </section>
      <img src={image} alt={altImage} />
    </section>
  );
}
