import React from "react";

export default function Achievement({ number, text }) {
  return (
    <section className="count-counter">
      <h2>{number}</h2>
      <h4>{text}</h4>
    </section>
  );
}
