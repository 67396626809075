import React from "react";

export default function Quality({ icon, text }) {
  return (
    <section className="quality">
      <img src={icon} alt={text + "-icon"} />
      <h3>{text}</h3>
    </section>
  );
}
