import React from "react";
import { Link } from "react-router-dom";

export default function BookMeet() {
  return (
    <section className="bookmeet">
      <article className="article">
        <h1>Ready to find out more?</h1>
        <p>
          Please contact our Sales Team for any information regarding our
          services and to discuss how they can benefit you. We monitor our
          enquiries inbox regularly and will respond to all requests as we are
          able to.
        </p>
        <ol>
          <li>
            Interpretation, translation and localisation services to help you
            trade anytime, anywhere
          </li>
          <li>Agile technology at the touch of a button</li>
          <li>Timely solutions available 24/7/365</li>
          <li>Strong, long-term client partnerships</li>
          <li>Delivering the best services every time</li>
        </ol>
      </article>
      <Link to="/bookademo">
        <button>BOOK A DEMO</button>
      </Link>
    </section>
  );
}
