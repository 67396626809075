import { useLayoutEffect, useRef } from "react";
import Header from "./components/Header";
import HomePage from "./pages/HomePage";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Footer from "./components/Footer";
import BookaMeet from "./pages/BookaMeet";
import Industries from "./pages/Industries";
import Opportunity from "./pages/Opportunity";

function App() {
  const whoweare = useRef(null);
  const whatwedo = useRef(null);
  const industry = useRef(null);

  return (
    <Router>
      <Header
        refss={{
          section1: whoweare,
          section2: whatwedo,
          section3: industry,
        }}
      />
      <Routes>
        <Route
          path="/"
          element={
            <HomePage
              refs={{
                section1: whoweare,
                section2: whatwedo,
                section3: industry,
              }}
            />
          }
        />

        <Route path="/bookademo" element={<BookaMeet />} />
        <Route path="/opportunity" element={<Opportunity />} />
        <Route path="/industry/:industries" element={<Industries />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
