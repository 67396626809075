import React from "react";

import logo from "../assets/logo-black.svg";
import loc_icon from "../assets/location-icon.svg";
import mail_icon from "../assets/mail-icon.svg";
import phn_icon from "../assets/phone-icon.svg";
import linedin_icon from "../assets/linkedin.svg";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer>
      <Link to="">
        <img className="logo" src={logo} alt="prospecct Communication's logo" />
      </Link>
      <article>
        <section>
          <div>
            <img
              src={loc_icon}
              alt="prospecct communication's primary location"
            />
            <a href="">Agimi Street, Institut Tirana, Tirana Albania, 1001</a>
          </div>
          <div>
            <img
              src={loc_icon}
              alt="prospecct communication's secondary location"
            />
            <a href="">
              Akaygen Street, Bebek, Istanbul, Istanbul Türkiye, 34342
            </a>
          </div>
        </section>
        <section>
          <div>
            <img
              src={mail_icon}
              alt="prospecct communication's primary email address"
            />
            <a href="mailto:contact@prospecctcommunication.com">
              contact@prospecctcommunication.com
            </a>
          </div>
          <div>
            <img
              src={mail_icon}
              alt="prospecct communication's secondary email address"
            />
            <a href="mailto:support@prospecctcommunication.com">
              support@prospecctcommunication.com
            </a>
          </div>
          <div>
            <img src={phn_icon} alt="prospecct communication's phone number" />
            <a href="tel:+447482128572">+447482128572</a>
          </div>
          <div>
            <h4>FOLLOW US ON : </h4>
            <a
              target="_blank"
              href="https://www.linkedin.com/company/prospecct-communication/"
            >
              <img src={linedin_icon} alt="linkedin prospect" />
            </a>
          </div>
        </section>
      </article>
      <section className="copyright">
        <p>&copy; 2024 Prospecct Communication. All Rights Reserved.</p>
        <a target="_blank" href="https://mehfooz.netlify.app">
          Designed and Developed by MS
        </a>
      </section>
    </footer>
  );
}
