import React from "react";
import Hero from "../components/Hero";
import WhoWeAre from "../components/WhoWeAre";
import WhatWeDo from "../components/WhatWeDo";
import Industry from "../components/Industry";
import BookMeet from "../components/BookMeet";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import hero1 from "../assets/hero/hero1.jpeg";
import hero2 from "../assets/hero/hero2.jpeg";
import hero3 from "../assets/hero/hero3.jpeg";
import hero4 from "../assets/hero/hero4.jpeg";

export default function HomePage({ refs }) {
  return (
    <>
      <Carousel
        autoPlay
        axis="horizontal"
        infiniteLoop
        centerSlidePercentage={100}
        swipeable
        showArrows={false}
        showStatus={false}
        preventMovementUntilSwipeScrollTolerance={true}
      >
        <Hero
          heroBg={hero1}
          heroText="Crossing Borders with Language"
          button={{ link: "/bookademo", text: "Get Started" }}
        />
        <Hero
          heroBg={hero2}
          heroText="Our interpreters deliver 24/7 accurate and reliable services."
        />
        <Hero heroBg={hero3} heroText="Working with diligence." />
        <Hero
          heroBg={hero4}
          heroText="Navigate the symphony of languages with our expert interpreting team."
          button={{ link: "/bookademo", text: "Book A Meet" }}
        />
      </Carousel>
      <WhoWeAre reference={refs.section1} />
      <WhatWeDo reference={refs.section2} />
      <Industry reference={refs.section3} />
      <BookMeet />
    </>
  );
}
