import React from "react";
import submit_icon from "../assets/submited.svg";
export default function Submited() {
  return (
    <section className="submit-form">
      <img src={submit_icon} alt="prospect accedmy submit icon" />
      <h4>
        Your Request is Submitted we will get back to you as soon as possible
      </h4>
    </section>
  );
}
