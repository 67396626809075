import React from "react";
import arrow from "../assets/arrow.svg";
import { Link } from "react-router-dom";
export default function MainQualities({ image, heading, link }) {
  return (
    <section>
      <img className="section-image" src={image} alt={image + "- prospecct"} />
      <h2>{heading}</h2>
      <Link to={`/industry/${heading}`}>
        Learn More <img src={arrow} alt="prospect arrow" />
      </Link>
    </section>
  );
}
