import React, { useEffect, useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import onsite_icon from "../assets/onsite.svg";
import Interpretation_icon from "../assets/interpretation.svg";
import Multilingual_icon from "../assets/multi.svg";
import Demand_icon from "../assets/ondemand.svg";
import opi_icon from "../assets/opi.svg";
import video_icon from "../assets/video.svg";
import rsi_icon from "../assets/rsi.svg";
import vri_icon from "../assets/vri.svg";

import tick from "../assets/tick.svg";
import Submited from "../components/Submited";
export default function BookaMeet() {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  const [isSubmited, setIsSubmited] = useState(false);
  const qualities = [
    {
      icon: onsite_icon,
      text: "Onsite Interpreter Scheduling",
    },
    {
      icon: Interpretation_icon,
      text: "Interpretation Management System",
    },
    {
      icon: Multilingual_icon,
      text: "Multilingual Events",
    },
    {
      icon: Demand_icon,
      text: "On-Demand Interpretation",
    },
    {
      icon: opi_icon,
      text: "Over the Phone Interpreting (OPI)",
    },
    {
      icon: video_icon,
      text: "Video Conferencing",
    },
    {
      icon: rsi_icon,
      text: "Remote Simultaneous Interpreting (RSI)",
    },
    {
      icon: vri_icon,
      text: "Video Remote Interpreting (VRI)",
    },
  ];
  const handleSubmit = (e) => {
    setIsSubmited(true);
  };
  useEffect(() => {
    if (isSubmited == true) {
      setTimeout(() => {
        setIsSubmited(false);
        document.getElementById("contactform").reset();
      }, 10000);
    }
  }, [isSubmited]);
  return (
    <>
      <div className="banner">
        <h6>Book a Meeting</h6>
      </div>
      <iframe
        style={{ display: "none" }}
        name="hidden_iframe"
        id="hidden_iframe"
      ></iframe>

      <form
        style={{
          opacity: isSubmited && 0,
          width: isSubmited && 0,
          height: isSubmited && 0,
        }}
        id="contactform"
        method="post"
        target="hidden_iframe"
        action="https://docs.google.com/forms/u/0/d/e/1FAIpQLSfWTp29pGjW5_zoO7bfHyq9M-6-jiN6gqrTWQErr8yGnwfsSQ/formResponse"
        onSubmit={handleSubmit}
      >
        <h3>What are you interested in?</h3>
        <div className="checkbox-container">
          {qualities.map((d, i) => (
            <label class="image-checkbox">
              <input
                required
                type="checkbox"
                name="entry.451907220"
                value={d.text}
              />
              <img className="icon" src={d.icon} alt={d.icon} />
              <span className="image-checkbox-text">{d.text}</span>
              <span className="tick">
                <img src={tick} />
              </span>
            </label>
          ))}
        </div>
        <div className="form-container">
          <input
            required
            type="text"
            name="entry.1191286040"
            placeholder="First Name"
          />
          <input
            required
            type="text"
            name="entry.1222221183"
            placeholder="Last Name"
          />
          <input
            required
            type="text"
            name="entry.806995711"
            placeholder="Job Title"
          />
          <input
            required
            type="text"
            name="entry.726606360"
            placeholder="Company"
          />
          <input
            required
            type="text"
            name="entry.436693698"
            placeholder="Email"
          />
          <input
            required
            type="text"
            name="entry.1857133447"
            placeholder="Telephone"
          />
          <input
            required
            type="text"
            name="entry.2038521524"
            placeholder="City"
          />
          <input
            required
            type="text"
            name="entry.162735732"
            placeholder="Country"
          />
          <div className="fullwidth">
            <label htmlFor="message">Please Provide any addition details</label>
            <textarea
              id="message"
              name="entry.349465712"
              rows="5"
              placeholder="Languages, deadlines, etc"
            ></textarea>
          </div>
          <button className="fullwidth width-16" type="submit">
            Submit
          </button>
        </div>
      </form>
      {isSubmited && <Submited />}
    </>
  );
}
